<!--/**
* Author: ganqz
* Date: 2021-11-27 17:20
* Desc: ResultInfo
*/
-->
<template>
  <zmAppPage :show-footer="true" :showHeaderLeft="true" title="历史成绩">
    <div class="pt20 plr15">
      <!-- <van-divider>本周答题模式最佳成绩</van-divider> -->
      <van-cell-group>
        <van-cell title="正确题数" :value="(score.score / 100).toFixed(0)" />
        <van-cell title="答题时长" :value="score.duration" />
      </van-cell-group>
      <!-- <van-divider>本周练习模式最佳成绩</van-divider> -->
      <!-- <van-cell-group>
        <van-cell title="正确题数" :value="(practiceScore.score/100).toFixed(0)" />
        <van-cell title="答题时长" :value="practiceScore.duration" />
      </van-cell-group> -->
    </div>
    <!-- <div class="mt20 tc">
      <van-button type="primary" @click="toAnswerHome">查看本次答题解析</van-button>
    </div> -->
    <div class="footer ptb10 plr15" slot="footer">
      <!-- <div class="tc mb10">本周答题次数剩余{{ answerTime }}次</div> -->
      <div class="df-center">
        <van-button class="flex-1" type="info" @click="toRankList">排行榜</van-button>
        <!-- <van-button round class="flex-1" type="danger" @click="toHome(1)" :disabled="!answerTime">进入答题</van-button> -->
        <!-- <van-button round class="flex-1" type="warning" @click="toHome(2)">练习模式</van-button> -->
      </div>
    </div>
  </zmAppPage>
</template>

<script>
import { PATH_HOME, PATH_RANK_LIST } from '@/router';
import { mapGetters } from 'vuex';
import { getSecondsCn } from '@/pages/home/getSecond';
export default {
  name: 'ResultInfo',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['resultInfo']),
    answerTime() {
      return 3 - this.score.number < 0 ? 0 : 3 - this.score.number;
    },
    score() {
      if (this.resultInfo.score) {
        return {
          ...this.resultInfo.score,
          duration: getSecondsCn(this.resultInfo.score.duration || 0)
        };
      } else {
        return {
          duration: getSecondsCn(0),
          score: 0
        };
      }
    }
    // practiceScore() {
    //   if (this.resultInfo.practiceScore) {
    //     return {
    //       ...this.resultInfo.practiceScore,
    //       duration: getSecondsCn(this.resultInfo.practiceScore.duration || 0)
    //     };
    //   } else {
    //     return {
    //       duration: getSecondsCn(0),
    //       score: 0
    //     };
    //   }
    // }
  },
  watch: {},
  created() {},
  methods: {
    toAnswerHome() {
      this.$router.push({ path: PATH_HOME, query: { examHistoryId: this.resultInfo.examHistoryId } });
    },
    toRankList() {
      this.$router.push({ path: PATH_RANK_LIST });
    },
    toHome(type) {
      this.$router.push({ path: PATH_HOME, query: { type } });
    }
  }
};
</script>
